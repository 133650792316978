import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout >
    <Seo title="404: Not found" />
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "rgb(235,235,235)" }}>
      <h1 style={{ marginTop: 20 }}>Erreur 404</h1>
      <p style={{ marginTop: 20 }}>Désolé il n'y a rien ici ...</p>
    </div>
  </Layout>
)

export default NotFoundPage
